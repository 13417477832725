import React, { FC, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Question, QuizAnswer } from 'types/quiz';
import { DynamicImage, Text } from 'components';
import {
  desktop,
  mobile,
  sxMobile,
  tablet,
  useQuery,
} from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import LCFooter from 'components/LCFooter';
import socProof from 'assets/images/landing/mobile-landing.png';
import socProof_des from 'assets/images/landing/desktop-landing.png';
import socProofLabor from 'assets/images/landing/mobile-landing-labor.png';
import socProof_desLabor from 'assets/images/landing/desktop-landing-labor.png';

import Logo from 'assets/icons/logo/logo.svg';
import BlackFridayBanner from 'components/BlackFridayBanner';
import { useFirstVisitDate, useScrollToTop } from 'utils/hooks';
import BlackFridayLandingLabel from './BlackFridayLandingLabel';
import Pill from 'assets/images/landing/pills.svg';
import Avocado from 'assets/images/landing/avocado.svg';
import Science from 'assets/images/landing/science.svg';
import Stimulant from 'assets/images/landing/stimulant.svg';
import Tracking from 'utils/tracking';
import StartNowButton from 'pages/start/results/components/StartNowButton';
import PeopleAskUs from 'components/PeopleAskUs';
import { ResultsFooter } from 'pages/start/results/components/ResultsFooter';
import { ReviewCards } from 'pages/start/checkout/components/ReviewCards';
import { Expectations } from 'pages/start/checkout/components/Expectations';
import { WithWithoutLanding } from 'pages/sections/WithWithoutLanding';
import { BenefitsPublication } from 'pages/start/results/components/BenefitsPublication';
import theme from 'utils/theme';
import { VideoTestimonials } from 'pages/start/checkout/components/VideoTestimonials';
import Triangle from 'assets/icons/green-triangle.svg';
import PRFooter from 'components/PRFooter';
import Header from 'components/reviews/Header';
import { useLocation } from '@reach/router';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
  hideFooter?: boolean;
}

interface ButtonsContainerProps {
  length: number;
}

const Landing: FC<LandingProps> = ({
  question,
  onOptionSelect,
  hideFooter = false,
}) => {
  const isBrowser = typeof window !== 'undefined';
  const { isTablet } = useQuery();
  // const { countdownDiffInMS } = useFirstVisitDate(); is not needed, blocks SSR
  const bfBannerExists = question?.bfBanner;
  const landingRef = useRef(null);
  const quiz = isBrowser
    ? new URLSearchParams(window.location.search).get('qz') ?? ''
    : '';
  const location = useLocation();
  const quizType = new URLSearchParams(location.search).get('qz') ?? 'main-nh';

  const pickSocialProfImage = (variant?: string) => {
    // if (variant === 'blackFriday') {
    //   return {
    //     mob: bfSocProof,
    //     des: bfSocProof_des,
    //   };
    // }
    // if (variant === 'xmas') {
    //   return {
    //     mob: xmasSocProof,
    //     des: xmasSocProof_des,
    //   };
    // }
    // if (variant === 'ny') {
    //   return {
    //     mob: nySocProof,
    //     des: nySocProof_des,
    //   };
    // }

    if (variant === 'labor') {
      return {
        mob: socProofLabor,
        des: socProof_desLabor,
      };
    }

    return {
      mob: socProof,
      des: socProof_des,
    };
  };

  const handleOptionsSelect = (item: any) => {
    const logEvent = (data: { category: string; label?: string }) => {
      Tracking.trackQuizQuestionAnswered({
        event: `Quiz question answered - 1`,
        category: `1 - ${data.category}`,
        label: data.label || '',
      });
    };

    logEvent({
      category: `${question.label}${
        question.optionsLabel ? ` - ${question.optionsLabel}` : ''
      }`,
      label: String(item?.label),
    });

    onOptionSelect({
      [question?.key]: item?.gender,
      label: item?.label,
    });
  };

  const handleScroll = (flag?: boolean) => {
    landingRef?.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  const renderGenderButtons = (options: any[]) => (
    <>
      {options?.map((option, index) => (
        <GenderButton
          key={index}
          color={option?.color}
          fontColor={option?.textColor}
          colorActive={option?.color}
          onClick={() =>
            handleOptionsSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          marginRight={index === 0}
          isSingleButton={question?.options?.length === 1}
        >
          {option?.label}
        </GenderButton>
      ))}
    </>
  );

  const renderGenderBasic = (options: any[]) => {
    if (!options.length) {
      return null;
    }
    return options?.map((option, index) => {
      const hasColor = !!option.color;
      return (
        <OtherGenderButton
          onClick={() =>
            handleOptionsSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          color={option?.color}
          colorActive={option?.color}
          key={index}
        >
          {hasColor ? (
            option?.label
          ) : (
            <Text type="body500">{option?.label}</Text>
          )}
        </OtherGenderButton>
      );
    });
  };

  const renderGenderOptions = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsContainer
        length={[...primaryOptions, ...secondaryOptions].length}
      >
        {renderGenderButtons(primaryOptions)}
        {renderGenderBasic(secondaryOptions)}
      </ButtonsContainer>
    );
  };

  const renderSections = (item: any, index: number) => {
    const key = `${item}-${index}`;
    switch (item.type) {
      // case 'publications': {
      //   return <BenefitsPublication key={key} {...item} />;
      // }
      case 'with_without': {
        return (
          <ContentWrapper
            key={key}
            $padding={isTablet ? '1.5rem 1rem' : '2.25rem 1rem'}
            $bgColor={theme.colors.novaPink}
          >
            <WithWithoutLanding key={key} {...item} />
            <StyledButton onClick={handleScroll}>Take the quiz</StyledButton>
          </ContentWrapper>
        );
      }
      case 'expectations': {
        return (
          <ContentWrapper
            key={key}
            $padding={isTablet ? '1.5rem 1rem' : '2.25rem 1rem'}
            $bgColor="#436460"
          >
            <Expectations {...item} />
            <StyledButton
              bgColor="#FEF3F3"
              fontColor="#000"
              onClick={handleScroll}
            >
              Take the quiz
            </StyledButton>
          </ContentWrapper>
        );
      }
      case 'video_testimonials': {
        return (
          <ContentWrapper
            key={key}
            $padding={isTablet ? '1.5rem 1rem' : '2.25rem 1rem'}
            $bgColor="#436460"
          >
            <VideoTestimonials {...item} />
          </ContentWrapper>
        );
      }
      case 'review_cards': {
        return (
          <ContentWrapper
            key={key}
            $padding={isTablet ? '1.5rem 1rem' : '2.25rem 1rem'}
            $bgColor={theme.colors.novaPink}
          >
            <ReviewCards
              fontColor="dark100"
              scrollTo={handleScroll}
              buttonText="Take the quiz"
              {...item}
            />
          </ContentWrapper>
        );
      }
      case 'peopleAskUsSection': {
        return (
          <ContentWrapper
            key={key}
            $bgColor={theme.colors.novaPink}
            $padding={isTablet ? '0rem 1rem' : '2.25rem 1rem'}
          >
            <CenterContent
              $width={'776px'}
              $maxWidth={isTablet ? '100%' : '776px'}
            >
              <PeopleAskUs {...item} />
            </CenterContent>
          </ContentWrapper>
        );
      }
      case 'quiz_footer': {
        return (
          <ResultsFooter
            bgColor={'#FEF3F3'}
            fontColor={'#000'}
            key={key}
            {...item}
          />
        );
      }
      default:
        break;
    }
  };

  const renderIcons = () => (
    <IconContainer>
      <Wrap>
        <ItemWrap>
          <Circle>
            <StyledPill />
          </Circle>
          <StyledText>Non-GMO</StyledText>
        </ItemWrap>
        <ItemWrap>
          <Circle>
            <StyledAvocado />
          </Circle>
          <StyledText>Keto-friendly</StyledText>
        </ItemWrap>
      </Wrap>
      <Wrap>
        <ItemWrap>
          <Circle>
            <StyledStimulant />
          </Circle>
          <StyledText>Stimulant free</StyledText>
        </ItemWrap>
        <ItemWrap>
          <CircleScience>
            <StyledScience />
          </CircleScience>
          <StyledText>Scientific ingredients</StyledText>
        </ItemWrap>
      </Wrap>
    </IconContainer>
  );

  useScrollToTop(0);
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <>
      <div ref={landingRef}>
        <Container hideFooter={hideFooter}>
          {/* {bfBannerExists ? (
          <BlackFridayBannerStyled
            {...question.bfBanner}
            countdownDiffInMS={countdownDiffInMS}
          />
        ) : null} */}
          {question?.bannerTopText && (
            <ScrollingTextWrapper>
              <ScrollingTextContent>
                {Array(30)
                  .fill(question?.bannerTopText)
                  .map((text, index) => (
                    <InnerBannerText key={index}>{text}</InnerBannerText>
                  ))}
              </ScrollingTextContent>
            </ScrollingTextWrapper>
          )}
          {quizType === 'main-nh' && (
            <Header isMobileApp={false} bgHex="#FEF3F3" />
          )}
          <Wrapper isFullHeight={quizType !== 'main-nh'}>
            {quizType !== 'main-nh' && (
              <HeaderContainer
                top={bfBannerExists || question?.bannerTopText ? '2.625rem' : 0}
                color="novaPink"
              >
                <HeaderImg src="assets/icons/logo.svg" alt="" />
                {isTablet ? (
                  <StyledHeaderText>SPERMIDINE</StyledHeaderText>
                ) : null}
              </HeaderContainer>
            )}
            <W1>
              <SocialProof
                mob={pickSocialProfImage(question?.socialProofVariant).mob}
                des={pickSocialProfImage(question?.socialProofVariant).des}
                isFullHeight={quizType !== 'main-nh'}
              />
            </W1>

            <WrappedItem
              top={bfBannerExists || question?.bannerTopText ? '114px' : '72px'}
            >
              <W3>
                <TitleContainer>
                  <Title
                    type={isTablet ? 'h1700' : 'h1XL'}
                    textAlign={isTablet ? 'left' : 'left'}
                    dangerouslySetInnerHTML={{ __html: question?.title || '' }}
                  />
                </TitleContainer>

                <DescriptionContainer>
                  <Label
                    type={isTablet ? 'body' : 'h3400'}
                    color="dark80"
                    textAlign={isTablet ? 'left' : 'left'}
                    dangerouslySetInnerHTML={{
                      __html: question?.description || '',
                    }}
                    descriptionIsMaxWidth={question?.descriptionIsMaxWidth}
                  />
                </DescriptionContainer>

                <ButtonsTitleContainer>
                  <Label
                    dangerouslySetInnerHTML={{ __html: question?.label }}
                    textAlign={isTablet ? 'left' : 'left'}
                    type={isTablet ? 'body500' : 'body500'}
                  />
                </ButtonsTitleContainer>
                <ButtonsMainConatiner>
                  {renderGenderOptions()}
                  {question?.bannerButtonText && (
                    <ButtonsBanner>
                      <LeftTriangle />
                      <RightTriangle />
                      {question?.bannerButtonText}
                    </ButtonsBanner>
                  )}
                </ButtonsMainConatiner>
                {renderIcons()}

                {question?.bfLabel ? (
                  <BlackFridayLandingLabel {...question.bfLabel} />
                ) : null}
              </W3>
            </WrappedItem>
          </Wrapper>
          {quiz === 'nvga2'
            ? question?.sections.map((item, index) =>
                renderSections(item, index),
              )
            : null}
          {quizType !== 'main-nh' && (
            <PRFooter disclaimerText={question?.disclaimerParagraphsPR || []} />
          )}
        </Container>
      </div>
    </>
  );
};

export default Landing;

const ButtonsBanner = styled.p`
  position: relative;
  display: flex;
  max-width: 21.4375rem;
  width: 100%;
  padding: 0.375rem 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  background: #2a4b42;
  color: #fef3f3;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin-top: 0.875rem;
  @media ${tablet} {
    max-width: unset;
  }
`;

const LeftTriangle = styled(Triangle)`
  position: absolute;
  top: -0.375rem;
  left: 20%;
`;

const RightTriangle = styled(Triangle)`
  position: absolute;
  top: -0.375rem;
  right: 20%;
`;

const ButtonsMainConatiner = styled.div`
  max-width: 21.4375rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${tablet} {
    max-width: unset;
  }
`;

const ScrollingTextWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  height: 2.625rem;
  display: flex;
  padding: 0.75rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  z-index: 100;
  background: #2a4b42;
`;

const ScrollingTextContent = styled.div`
  display: inline-block;
  position: absolute;
  left: 0;
  will-change: transform;
  animation: scroll 100s linear infinite;
  color: #fef3f3;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  padding-right: 100%;
  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }

  @keyframes scroll {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }
`;

const InnerBannerText = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  line-height: 1.1;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  flex-wrap: wrap;
`;

const Circle = styled.div`
  display: flex;
  border: 0.5px solid #2a4b42;
  border-radius: 180px;
  height: 2.5rem;
  width: 2.5rem;
  align-items: center;
  justify-content: center;
`;

const CircleScience = styled.div`
  display: flex;
  border: 0.5px solid #2a4b42;
  border-radius: 180px;
  height: 2.5rem;
  width: 2.8rem;
  align-items: center;
  justify-content: center;
`;
const StyledPill = styled(Pill)``;
const StyledAvocado = styled(Avocado)``;
const StyledStimulant = styled(Stimulant)``;
const StyledScience = styled(Science)``;

const StyledText = styled(Text)`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 0.975rem */
  letter-spacing: -0.018rem;
  color: #2a4b42;
  @media ${sxMobile} {
    font-size: 0.5rem;
  }
`;

const ItemWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  max-width: 9.375rem;
  width: 100%;
  margin: 0 0 1rem;
`;

const IconContainer = styled.div`
  display: flex;
  max-width: 38.8rem;
  width: 100%;
  justify-content: space-between;
  margin-top: 2.25rem;
  flex-wrap: wrap;
`;

const W3 = styled.div`
  max-width: 720px;
  width: 100%;
  @media ${tablet} {
    margin-right: unset;
    margin: auto;
  }
`;

const W1 = styled.div`
  display: flex;
  flex: 1;
  max-width: 50%;

  @media ${tablet} {
    max-width: 100%;
    flex: 0;
    padding: 0;
  }
`;

const Container = styled.div<{ hideFooter?: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.novaPink};
`;

const WrappedItem = styled.div<{ top: string }>`
  display: flex;
  flex: 1;
  flex-direction: inherit;
  margin-left: auto;
  padding-top: ${({ top }) => top};
  @media ${tablet} {
    padding: 0 1rem;
    width: unset;
    margin-left: unset;
    flex: 0;
  }
`;

const Wrapper = styled.div<{ isFullHeight?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex: 1;
  gap: 3rem;
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  padding: 0 3rem;
  background: ${({ theme }) => theme.colors.novaPink};
  ${({ isFullHeight }) => isFullHeight && 'min-height: 100vh;'}
  @media ${tablet} {
    flex-direction: column;
    align-items: unset;
    gap: 1.5rem;
    padding: 0;
  }
`;

const HeaderImg = styled(Logo)`
  width: 5.66869rem;
  height: 1.75rem;
`;

const HeaderContainer = styled.header<{ top?: string }>`
  position: absolute;
  top: ${({ top = '0' }) => top};
  left: 3rem;
  display: flex;
  align-items: center;
  height: 72px;
  margin: 0 auto;
  width: 100%;
  background: ${({ theme }) => theme.colors.novaPink};

  @media ${tablet} {
    position: static;
    height: 56px;
    align-items: center;
    justify-content: space-between;
    padding-left: unset;
    padding: ${({ top }) => (top ? `4.5rem 1rem 1.5rem` : '0 1rem')};
  }
`;

const slideV = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 10000%;
  }
`;

const slideH = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000% 0;

  }
`;

// const SocialProof = styled.div`
//   position: relative;
//   background-size: cover;
//   animation: ${slideV} 1500s linear infinite;
//   width: 100%;
//   background-image: url(${socProof_des});
//   height: 80vh;

//   @media ${tablet} {
//     background-image: url(${socProof});
//     animation: ${slideH} 1500s linear infinite;
//     height: 300px;
//   }
// `;

const SocialProof = styled.div<{
  des: string;
  mob: string;
  isFullHeight?: boolean;
}>`
  position: relative;
  background-size: cover;
  animation: ${slideV} 1500s linear infinite;
  width: 100%;
  background-image: url(${({ des }) => des});
  height: ${({ isFullHeight }) => (isFullHeight ? '100vh' : '85.5vh')};
  @media ${tablet} {
    animation: ${slideH} 1500s linear infinite;
    background-image: url(${({ mob }) => mob});
    height: 308px;
  }
`;

const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  gap: 0.9375rem;
  @media ${tablet} {
  }
`;

const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.green110};
  @media ${tablet} {
    text-align: left;
  }
`;

const Label = styled(Text)<{
  descriptionIsMaxWidth?: boolean;
  fontSizing?: number;
}>`
  * {
    font-size: inherit;
  }
  color: ${({ theme }) => theme.colors.green110};
  @media ${tablet} {
    /* width: ${({ descriptionIsMaxWidth }) =>
      descriptionIsMaxWidth ? '100%' : '21.438rem'}; */
  }
`;

const DescriptionContainer = styled.div`
  padding-bottom: 3rem;
  max-width: 624px;
  @media ${tablet} {
    text-align: left;
    text-align-last: left;
    padding-bottom: 1.5rem;
    display: flex;
  }
`;

const TitleContainer = styled.div`
  padding-bottom: 1.5rem;
  justify-content: center;
  display: flex;
  width: 100%;

  @media ${tablet} {
    text-align: left;
    padding-bottom: 1rem;
  }
`;

const GenderButton = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})<{ marginRight?: boolean; isSingleButton?: boolean }>`
  padding: 0 1rem;
  width: 100%;
  height: 3.5rem;
  max-width: 10.25rem;

  @media ${tablet} {
    height: 3rem;
    max-width: unset;
  }
`;

const OtherGenderButton = styled(GenderButton)`
  background-color: ${({ theme, color }) =>
    color ? theme.colors[color] : 'transparent'};

  @media ${tablet} {
  }
`;

const ButtonsTitleContainer = styled.div`
  padding-bottom: 1.5rem;

  @media ${tablet} {
    text-align: center;
    padding-bottom: 0.75rem;
    display: flex;
  }
`;

const StyledHeaderText = styled(Text)`
  color: #436460;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`;

const ContentWrapper = styled.div<{ $padding?: string; $bgColor?: string }>`
  background-color: ${({ $bgColor, theme }) => $bgColor || theme.colors.light0};
  padding: ${({ $padding }) => $padding || 0};
`;
const CenterContent = styled.div<{
  $width?: string;
  $maxWidth: string;
  $padding?: string;
  $bgColor?: string;
  $borderRadius?: string;
}>`
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: ${({ $bgColor }) => $bgColor || 'transparent'};
  width: ${({ $width }) => $width || 'auto'};
  max-width: ${({ $maxWidth }) => $maxWidth || 'auto'};
  padding: ${({ $padding }) => $padding || 0};
  border-radius: ${({ $borderRadius }) => $borderRadius || '16px'};
`;

const StyledButton = styled(StartNowButton)<{
  bgColor?: string;
  fontColor?: string;
}>`
  width: 100%;
  max-width: 21.4375rem;
  margin: 3rem auto 0rem;
  background-color: ${({ bgColor }) => bgColor || '#000'};
  color: ${({ fontColor }) => fontColor || '#FFF'};
  @media ${mobile} {
    max-width: 100%;
    margin-top: 1.5rem;
  }
`;
